<template>
  <div class="mantenimientos">
    <AirtableMantenimientos/>
  </div>
</template>

<script>
import AirtableMantenimientos from '../components/airtableMantenimientos.vue'
// @ is an alias to /src


export default {
  name: 'Mantenimientos',
  components: {
    AirtableMantenimientos,
  }
}
</script>
